
import React,{ useState, useEffect} from 'react'
import logo from './images/logo.png'
import search from './images/searchIcon.svg'
import './App.css';
import Routing from './Routing'

function App() {
 
  return (
    <div className="App">
      <Routing/>
    </div>
  );
}

export default App;

