import React, {Component} from 'react'
import exclamationImg from '../images/exclamationErrorIcon.svg';
function ErrorMessageComponent (props) {
    return (
        <div className="positionRelative fullWidth">
            <div className="errorMessageWithBackground positionAbsoute">
                <div className="exclamationErrorIcon"><img src={exclamationImg}/></div>
                <div className='errorTextContent'>{props.errorMessage}</div>
            </div>
        </div>

    )
}

export default  ErrorMessageComponent;