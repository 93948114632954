const ENV = 'DEV';
//const ENV = 'QA';
export const ALL_CONFIG = {
    'DEV_BASE_URL': 'https://vnswu23h1h.execute-api.us-east-1.amazonaws.com/staging/v1/',

    'QA_BASE_URL': 'https://vnswu23h1h.execute-api.us-east-1.amazonaws.com/staging/v1/',
}

export const CONFIG = {
    'baseUrl': ALL_CONFIG[ENV + '_BASE_URL']
}

export default CONFIG;

