import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
// import Home from './components/Home';

import Content from './Components/Content';
import SignIn   from './Components/SingIn';
import SignUp   from './Components/SignUp';

class Routing extends Component {
  render() {
    return (
    <Router>
        <div>
          <Switch>
              <Route exact path='/' component={SignIn} />
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/Signup' component={SignUp} />
              <Route path='/content' component={Content} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Routing;