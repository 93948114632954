import React, {Component} from 'react';
import ErrorMessageComponent from "./ErrorMessageComponent";
import logo from '../images/logo.png'
import LoaderFullScreen from "./Loading";
import { Visibility, VisibilityOff, Check } from '@material-ui/icons';
const axios = require('axios');
const validateEmail = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    return expression.test(String(email).toLowerCase())
}


class SignUp extends Component {
    constructor(props) {
        super(props)
        /* Initial application state */
        this.state = {
            userEmail: '',
            userEmailForgotPassword: '',
            password: '',
            loading: false,
            passwordShown:false,
            disabled: true,
            errorMsg: '',
            open: false,
            textVisible: false,
            passwordType: false,
            error:false,
            errorMessage:false,
            passwordSuccessMessage:false,
            passwordConditionBox: false,
            passwordErrorHide:false,
            passwordCondition:[
                {
                    type:'lowercaseCharacter',
                    name: 'One lowercase character',
                    status: false
                },
                {
                     type:'specialCharacter',
                     name: 'One special character',
                     status: false
                },
                {
                    type:'uppercaseCharacter',
                    name: 'One uppercase character',
                    status: false
                },
                {
                    type:'minimumCharacters',
                    name: '8 characters minimum',
                    status: false
                },
                {
                    type:'numberOnly',
                    name: 'Should be one number',
                    status: false
                }
            ]
        }

    }

    componentDidMount = async () => {

    }


    handleOnChangeEmail = (event) => {
        this.setState({
            userEmail: event.target.value.toLowerCase(),
            errorMsg: ''
        })

        const validEmail = validateEmail(event.target.value)

        if (validEmail && this.state.password.length > 4) {
            this.setState({
                disabled: false
            })
        }
    }

    handleOnChangePassword = (event) => {
       
        // if (event.target.value.length > 4) {
        //     const validEmail = validateEmail(this.state.userEmail)
        //     if (validEmail) {
        //         this.setState({
        //             disabled: false
        //         })
        //     }
        // } else {
        //     this.setState({
        //         disabled: true
        //     })
        // }

        if(event.target.value == " "){
            return
        }else{
        let count = 0;
        if(event.target.value.length > 0){
            this.setState({passwordConditionBox:true})
        }
        else{
            this.setState({passwordConditionBox:false})
        }
        let lowercaseCharacter = /[a-z]/g;
        if(event.target.value.match(lowercaseCharacter)) {
            this.validatePassword('lowercaseCharacter', true)
            count++
        } else {
            this.validatePassword('lowercaseCharacter', false)
        }
        let uppercaseCharacter = /[A-Z]/g;
        if(event.target.value.match(uppercaseCharacter)) {
            this.validatePassword('uppercaseCharacter', true)
            count++
        } else {
            this.validatePassword('uppercaseCharacter', false)
        }
        let numberOnly = /[0-9]/g;
        if(event.target.value.match(numberOnly)) {
            this.validatePassword('numberOnly', true)
            count++
        } else {
            this.validatePassword('numberOnly', false)
        }
        let specialCharacter = /[ `!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/? ~]/;
        if(event.target.value.match(specialCharacter)) {
            this.validatePassword('specialCharacter', true)
            count++
        } else {
            this.validatePassword('specialCharacter', false)
        }
        if(event.target.value.length >= 8) {
            this.validatePassword('minimumCharacters', true)
            count++
        } else {
            this.validatePassword('minimumCharacters', false)
        }
            if(count == this.state.passwordCondition.length) {
                this.setState({passwordSuccessMessage:true, passwordErrorHide:false})
            }else{
                this.setState({passwordSuccessMessage:false, passwordErrorHide:true})
            }
            this.setState({
                password: event.target.value,
                errorMsg: ''
            })
        }
    }

     validatePassword = (type, status) => {
        this.state.passwordCondition.map((item, index) => {
            if(item.type == type){
                item.status = status
            }
        })

    }

    goToSignInScreen = () => {
        this.props.history.push('/signin')
    }

    doSignup = async () => {
        this.setState({loading:true, error:false})
        let res = await axios.post("https://jdf7090b18.execute-api.us-east-1.amazonaws.com/dev/signup", {
            email: this.state.userEmail,
            password: this.state.password
        })
        this.setState({loading:false})
        if (!res.data.Response.IsError) {
            this.props.history.push('/signin')
        }else{
            this.setState({error:res.data.error, errorMessage:'Invalid request'})
        }
    }
     togglePasswordVisiblity = () => {
        this.setState({passwordShown:!this.state.passwordShown})
    };
    render() {
        return (
            <div className='signInMain'>
                {this.state.loading ?
                    <LoaderFullScreen/>
                    :
                    null
                }
                <div className='header fullWidth'>
                    <div className='logo'>
                        <img src={logo} alt='logo'/>
                        <span className='mediaServicePanel'>Media service panel</span>
                    </div>
                </div>
                <div id="login-form-wrap">
                    <h2>Sign Up</h2>
                    {this.state.error ?
					<div className='maxWidth600 marginLeftRightAuto'>
						<ErrorMessageComponent errorMessage={this.state.errorMessage} />
					</div>
                    :
                    null
                    }
                    <div className='fullWidth'>
                        <form id="login-form">
                            <div className='fullWidth'>
                                <input
                                    className='inputCol'
                                    onChange={this.handleOnChangeEmail}
                                    placeholder="Enter your email"
                                    type="email"
                                    name="email"
                                    value={this.state.userEmail}
                                    required=""/>
                                <i className="validation"><span></span><span></span></i>
                            </div>
                            <div className='fullWidth positionRelative'>
                                <input
                                    className='inputCol'
                                    onChange={this.handleOnChangePassword}
                                    placeholder="Enter your password"
                                    name="password"
                                    type={this.state.passwordShown ? "text" : "password"}
                                    required=""/>
                                     <div className="passwordTextShow" onClick={this.togglePasswordVisiblity}>
                                {
                                    this.state.passwordShown ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                            <path d="M13 7C13 8.657 11.657 10 10 10C8.343 10 7 8.657 7 7C7 5.343 8.343 4 10 4C11.657 4 13 5.343 13 7Z" fill="#A9AEB1"/>
                                            <path d="M19.8938 6.55299C17.7358 2.23599 13.9028 0 9.99976 0C6.09676 0 2.26375 2.23599 0.10575 6.55299C-0.03525 6.83399 -0.03525 7.16601 0.10575 7.44701C2.26375 11.764 6.09676 14 9.99976 14C13.9028 14 17.7358 11.764 19.8938 7.44701C20.0348 7.16601 20.0348 6.83399 19.8938 6.55299ZM9.99976 12C7.03076 12 3.99777 10.379 2.12977 7C3.99777 3.621 7.03076 2 9.99976 2C12.9688 2 16.0018 3.621 17.8698 7C16.0018 10.379 12.9688 12 9.99976 12Z" fill="#A9AEB1"/>
                                        </svg>
                                        :
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.70676 0.29325C2.31676 -0.09775 1.68276 -0.09775 1.29276 0.29325C0.901762 0.68325 0.901762 1.31725 1.29276 1.70725L3.71677 4.13125C2.28577 5.20725 1.03875 6.68524 0.10575 8.55324C-0.03525 8.83424 -0.03525 9.16626 0.10575 9.44726C2.26375 13.7643 6.09676 16.0002 9.99976 16.0002C11.5548 16.0002 13.0988 15.6453 14.5308 14.9453L17.2928 17.7073C17.6828 18.0983 18.3168 18.0983 18.7068 17.7073C19.0978 17.3173 19.0978 16.6832 18.7068 16.2932L2.70676 0.29325ZM13.0138 13.4282C12.0338 13.8112 11.0128 14.0003 9.99976 14.0003C7.03076 14.0003 3.99777 12.3793 2.12977 9.00025C2.94677 7.52125 3.98777 6.37925 5.14777 5.56325L7.29175 7.70725C7.10475 8.09825 6.99976 8.53725 6.99976 9.00025C6.99976 10.6573 8.34276 12.0003 9.99976 12.0003C10.4628 12.0003 10.9018 11.8952 11.2928 11.7082L13.0138 13.4282Z" fill="#A9AEB1"></path>
                                            <path d="M16.5519 10.895C17.0349 10.34 17.4779 9.709 17.8699 9C16.0019 5.621 12.9689 4 9.99991 4C9.88791 4 9.77591 4.002 9.66391 4.007L7.87891 2.22301C8.57691 2.07501 9.28791 2 9.99991 2C13.9029 2 17.7359 4.23599 19.8939 8.55299C20.0349 8.83399 20.0349 9.16601 19.8939 9.44701C19.3499 10.535 18.6999 11.491 17.9689 12.312L16.5519 10.895Z" fill="#A9AEB1"></path>
                                        </svg>
                                }

                            </div>
                                <i className="validation"><span></span><span></span></i>
                            </div>
                            {this.state.passwordConditionBox ?
                            <div className="passwordMatchBox fullWidth">
                                <ul>
                                    {
                                        this.state.passwordCondition.map((item, index) => {
                                            // console.log('item', item[Object.keys(item)[0]])
                                            // console.log('item-', passwordCondition['lowercaseCharacter'])
                                            return(
                                                <li key={index} className='passowrdListOfCondition'>
                                                    <span className='rightIocn'><Check className={item.status ? 'checkIconActive':'checkIcon'}/></span>
                                                    <span className='passowrdListOfConditionText'>{item.name}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            null
                        }
                            <div className='fullWidth'>
                                <input type="button" id="login" className='buttonSubmit' value="Sign Up"
                                       onClick={this.doSignup}/>
                            </div>
                        </form>
                    </div>
                    <div className='fullWidth' id="signUpcol">
                        <p>Already a member? <span onClick={this.goToSignInScreen}>Sign In</span></p>
                        <p>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignUp;

