import React, {useState, useEffect, useCallback} from 'react'
import { useHistory } from "react-router";
import logo from '../images/logo.png'
import search from '../images/searchIcon.svg'
import refresh from '../images/refresh-27.svg'
import LoaderFullScreen from './Loading'
import {client, secureClient} from '../config/axiosClient'
import '../App.css';
import TablePagination from '@mui/material/TablePagination';
import {useDropzone} from 'react-dropzone'

const axios = require('axios');


function Content(props) {
    const [list, setList] = useState([]);
    const [list1, setList1] = useState([]);
    const [screen1, setScreen1] = useState(true);
    const [screen2, setScreen2] = useState(false);
    const [isDetail, setIsDetail] = useState(true);
    const [isPlayback, setIsPlayback] = useState(false);
    const [loaderFullScreenVisible, setLoaderFullScreenVisible] = useState(false);
    const [assetName, setAssetName] = useState('');
    const [clientName, setClientName] = useState(localStorage.getItem("client"));
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const [guid, setGuid] = useState('');
    const [hSL_URL, setHSL_URL] = useState('');
    const [dash_URL, setDash_URL] = useState('');
    const [mp4, setMp4] = useState('');
    const [user, setUser] = useState({});
    const [max, setMax] = useState(11);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(2);
    const [nextValue, setNextValue] = useState({})
    const [selectedItem, setSelectedItem] = useState({})
    const [backDisabled, setBackDisabled] = useState(false)
    const [nextDisabled, setNextDisabled] = useState(false)
    const [searchStr, setSearchStr] = useState('')
    const [existingList, setExistingList] = useState([])
    let history = useHistory()
    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        setLoaderFullScreenVisible(true)
        console.info("acceptedFiles: ", acceptedFiles)
        console.info("fileRejections: ", fileRejections)
        for(let file of acceptedFiles){
            console.info("file: ", file.name)
            console.info("file: ", file.type)
            uploadFile(file)
        }
        for(let fileRejected of fileRejections){
            console.info("fileRejected: ", fileRejected.name)
            console.info("fileRejected: ", fileRejected.type)
        }
        // Do something with the files
    }, [])
    const uploadFile = async(file) =>{
        try {
            const uriArr = file.name.split('.');
            let fileType = uriArr[uriArr.length - 1]
            let fileName = ""
            for(let i=0; i<uriArr.length-1; i++){
                fileName += uriArr[i]
            }
            //let fileName = uriArr[uriArr.length - 1]
            let reader = new FileReader();
            reader.readAsDataURL(file);
            let base64
            reader.addEventListener('loadend', async () =>
                     base64 = await reader.result
            );
            let signedUrlRes = await secureClient.post(clientName+"/media/signed-url", {
                "extension": fileType,
                "filename": fileName
            })
            console.info("signedUrlRes.url: ", signedUrlRes.data.url)
            console.log("base64", base64)
            let fileResp = await fetch(base64);
            let Blobdata = await fileResp.blob();
            let uploadDate = new Date();
            let uploadResBody = await axios.put(signedUrlRes.data.url, Blobdata)
            console.log("uploadResBody", uploadResBody)
            setTimeout(goToScreen1, 10000)
            setLoaderFullScreenVisible(false)
        }catch(e){
            console.error("Error ", e)
            setLoaderFullScreenVisible(false)
        }
        //await goToScreen1()
    }
    const {getRootProps, getInputProps, isDragActive, fileRejections, acceptedFiles} = useDropzone({
        onDrop,
        accept: "application/mp4,video/mp4,application/json",
        multiple: false
    })
    /*const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({file, errors}) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));*/

    useEffect(async () => {
        let updatedList = []
        console.log("localStorage.getItem(\"client\")",localStorage.getItem("client"))
        let cName = localStorage.getItem("client")
        console.log("clientName",cName)
        await setClientName(cName)
        console.log("clientName",clientName)
        async function fetchData(max) {
            let res = await secureClient.get(localStorage.getItem("client")+'/media/reconcile?limit=11')
            console.info('data: ', res.data)
            if (res.data.next) {
                setBackDisabled(true)
                setNextDisabled(false)
            } else {
                setBackDisabled(true)
                setNextDisabled(true)
            }
            let resData = res.data.data
            if(resData.length===max){
                resData.pop()
            }
            setList(resData)
            setExistingList(resData)
            setNextValue({0: {current: '', next: res.data.next}})

        }

        fetchData(max);
    }, [list1])

    const nextRecord = async (page, limit) => {
        console.info("page", page)
        console.info("limit", limit)
        setLoaderFullScreenVisible(true)
        let nextVal = page === 0 ? '' : nextValue[page - 1].next
        let res = await secureClient.get(clientName+`/media/reconcile?limit=${limit}&next=${nextVal}`)
        if (res.data.next) {
            page === 0 ? setBackDisabled(true) : setBackDisabled(false)
            res.data.data.length < limit ? setNextDisabled(true) : setNextDisabled(false)
        } else {
            page === 0 ? setBackDisabled(true) : setBackDisabled(false)
            res.data.data.length < limit ? setNextDisabled(true) : setNextDisabled(false)
        }
        let resData = res.data.data
        if(resData.length===max){
            resData.pop()
        }
        setList(resData)
        setExistingList(resData)
        let existingNextValue = nextValue;
        if (!existingNextValue[page]) {
            existingNextValue[page] = {}
        }
        existingNextValue[page].current = page === 0 ? '' : nextValue[page - 1].next
        existingNextValue[page].next = res.data.next
        setNextValue(existingNextValue)
        setLoaderFullScreenVisible(false)
    }

    const goToScreen2 = async (item) => {
        setSelectedItem(item);
        console.info('item--------', item)
        let userInfo = {}
        let url = clientName+'/media/' + item.id
        let resData = await secureClient.get(url)
        console.info("resData.data: ", resData.data)
        setUser(resData.data)
        userInfo = resData.data
        if (userInfo) {
            setGuid(userInfo.id ? userInfo.id : null);
            setAssetName(userInfo.title ? userInfo.title : null);
            setHSL_URL(userInfo.assets.hls ? userInfo.assets.hls : null);
            setDash_URL(userInfo.assets.mpd ? userInfo.assets.mpd : null)
            setMp4(userInfo.assets.mp4 ? userInfo.assets.mp4['720'] : null);
            setType(userInfo.job_type ? userInfo.job_type : null);
            setStatus(userInfo.state ? userInfo.state : null);
        }
        setScreen1(false)
        setScreen2(true)
    }
    const goToScreen1 = async () => {
        setList1([])
        setScreen1(true)
        setScreen2(false)
        setPage(0);
    }
    const activeDetailTab = () => {
        setIsDetail(true)
        setIsPlayback(false)
    }
    const activePlayBackTab = () => {
        setIsDetail(false)
        setIsPlayback(true)
    }
    const convertHMS = (value) => {
        const sec = parseInt(value, 10); // convert value to number if it's string
        let hours = Math.floor(sec / (1000 * 3600)); // get hours
        let minutes = Math.floor(((sec / 1000) - (hours * 3600)) / 60); // get minutes
        let seconds = Math.floor(((sec / 1000) - (hours * 3600) - (minutes * 60))); //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
    }


    const handleChangePage = (event, newPage) => {
        nextRecord(newPage, max)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setMax(event.target.value);
        nextRecord(0, event.target.value)
        setPage(0);
    };

    const labelDisplayedRows = (event) => {
        return ""
    };

    const doSignOut = async () => {
        //let history = useHistory  ()
        setLoaderFullScreenVisible(true)
        localStorage.setItem("UserData", "");
        localStorage.setItem("isLoggedIn", "");
        localStorage.setItem("client", "");
        setLoaderFullScreenVisible(false)
        history.push({pathname:'/signin'})
    }

    const goToSignInScreen = () => {
        props.history.push('/signin')
    }
    const handleFilterChange = (e) => {
        let existingListToSearch = existingList
        if(e.target.value && e.target.value!='') {
            let searchValue = e.target.value.toLowerCase()
            existingListToSearch = existingListToSearch.filter(function (val) {
                console.log("val.job_status", val.state.toLowerCase())
                console.log("val.job_status", val.state.toLowerCase().includes(e.target.value))
                if (val.state.toLowerCase().includes(searchValue) || val.title.toLowerCase().includes(searchValue) || val.id.toLowerCase().includes(searchValue) || val.job_type.toLowerCase().includes(searchValue)) {
                    return val
                } else {
                    return null
                }
            })
            setList(existingListToSearch)
        }else{
            setList(existingList)
        }
        console.log("e.target.value", e.target.value)
        setSearchStr(e.target.value)
    }

    return (
        <div>
        { localStorage.getItem("isLoggedIn") && localStorage.getItem("isLoggedIn")==="true"?
        <div className="App">
            {loaderFullScreenVisible ?
                <LoaderFullScreen/>
                :
                null
            }
            <div className='header fullWidth'>
                <div className='logo'>
                    <img src={logo} alt='logo'/>
                    <span className='mediaServicePanel'>Media service panel</span>
                </div>
                <div className='navCol'>
                    <ul>
                        <li onClick={() => {
                            goToScreen1()
                        }}><span className="navText"> Content</span></li>
                        <li><span className="navText"> Channel</span></li>
                        <li><span className="navText"> Event</span></li>
                        {/* <li ><span className="navText"> </span></li> */}
                    </ul>
                    <div onClick={() => {
                            doSignOut()
                        }} 
                        className='logout'>Logout</div>
                </div>
            </div>
            {screen1 ?
                <div className='containerMain fullWidth'>
                    <section className="container">
                       <div className="dragDropCol">  
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>Drag 'n' drop content files here, or click to select files</p>
                            }
                        </div>
                        {/*<aside>
                            <h4>Accepted files</h4>
                            <ul>{acceptedFileItems}</ul>
                            <h4>Rejected files</h4>
                            <ul>{fileRejectionItems}</ul>
                        </aside>*/}
                        </div>
                    </section>
                    <div className='title fullWidth '>
                        <h2 className='abc'>Content</h2>
                        <div className='tableActionBUtton'>
                            <div className='searchBox'>
                                <span>
                                    {/*<svg xmlns="http://www.w3.org/2000/svg" className="svg-icon"
                                         style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
                                         viewBox="0 0 1024 1024" version="1.1">
                                        <path d="M512 192a320 320 0 1 0 285.12 465.408 32 32 0 0 1 56.96 29.184 384 384 0 1 1-61.312-436.608l48.64-48.64A32 32 0 0 1 896 224v128a31.872 31.872 0 0 1-32 32h-128a31.872 31.872 0 0 1-22.656-54.656l34.112-34.112A319.616 319.616 0 0 0 512 192z"/>
                                    </svg>*/}
                                    <img className='refreshContent' src={refresh} alt='search' onClick={goToScreen1}/>
                                </span>
                                <input
                                    type="text"
                                    id="table-search"
                                    placeholder="Search"
                                    name="search"
                                    onChange={handleFilterChange}
                                    value={searchStr}
                                />
                                <button type="submit" className='searchCol'>
                                    <img src={search} alt='search'/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <table cellSpacing="0" cellPadding="0" border="0" className="fullWidth" id="TableContent">
                        <thead>
                        <tr>
                            <th className=''>
                                <input type='checkbox'/>
                            </th>
                            <th className=''>Asset Name</th>
                            <th className=''>GUID</th>
                            <th className=''>Source</th>
                            <th className=''>Added</th>
                            <th className=''>Duration</th>
                            <th className=''>Type</th>
                            <th className=''>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list && list.length > 0 && list.sort((a,b) => b.created-a.created).map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td><span><input type='checkbox'/></span></td>
                                    <td onClick={() => {
                                        goToScreen2(item)
                                    }}><span>{item.title}</span></td>
                                    <td><span>{item.id}</span></td>
                                    <td><span>{item.source_bucket}</span></td>
                                    <td><span>{new Date((item.created)).toString()}</span></td>
                                    <td><span>{convertHMS(item.duration)}</span></td>
                                    <td><span>{item.job_type}</span></td>
                                    <td className={(item.state != 'ready' && item.state != 'error') ? 'loader' : item.state === 'error' ? 'error' : ''}>
                                        <span>{item.state}</span></td>

                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    <div className='fullWidth'>
                        <TablePagination
                            component="div"
                            count={-1}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={max}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            backIconButtonProps={{disabled: backDisabled}}
                            nextIconButtonProps={{disabled: nextDisabled}}
                            labelDisplayedRows={labelDisplayedRows}
                            rowsPerPageOptions={[]}
                        />
                    </div>
                </div>
                :
                null
            }
            {screen2 ?
                <div className='fullWidth'>
                    <div className='title fullWidth'>
                        <div className='fullWidth secondScreenContainer'>
                            <div className='assetList'>
                                <h2 className='contentTitle'>Content</h2>
                                <ul>
                                    {
                                        list && list.map((item, i) => {
                                            return (
                                                <li key={i} onClick={() => {
                                                    goToScreen2(item)
                                                }}
                                                    className={selectedItem.title === item.title ? 'activeItem' : 'inactiveItem'}>{item.title}</li>

                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='secondMainCol fullWidth'>
                                <div className='secondScreenTitle'> {user.title}</div>
                                <div className='tabRow fullWidth'>
                                    <ul>
                                        <li onClick={activeDetailTab} className={isDetail ? 'tabActive' : null}>
                                            <span>Details</span></li>
                                        <li><span>Metadata</span></li>
                                        {/*<li><span>Breaks</span></li>*/}
                                        <li onClick={activePlayBackTab} className={isPlayback ? 'tabActive' : null}>
                                            <span>PlayBack</span></li>
                                        <li><span>Export</span></li>
                                    </ul>
                                </div>
                                <div className='tabContainer'>
                                    {
                                        isDetail ?
                                            <div className='detailTabCol fullWidth'>
                                                <div className='inpCol'>
                                                    <label>Asset Name</label>
                                                    <input
                                                        name='asset_Name'
                                                        value={assetName}
                                                        className='inputCol'
                                                        onChange={(e) => setAssetName(e.target.value)}
                                                    />
                                                </div>
                                                <div className='inpCol'>
                                                    <label>GUID</label>
                                                    <input
                                                        name='guid'
                                                        value={guid}
                                                        className='inputCol'
                                                        onChange={(e) => setGuid(e.target.value)}
                                                    />
                                                </div>
                                                <div className='inpCol'>
                                                    <label>Type</label>
                                                    <input
                                                        name='type'
                                                        value={type}
                                                        className='inputCol'
                                                        onChange={(e) => setType(e.target.value)}
                                                    />
                                                </div>
                                                <div className='inpCol'>
                                                    <label>Status</label>
                                                    <input
                                                        name='status'
                                                        value={status}
                                                        className='inputCol'
                                                        onChange={(e) => setStatus(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        isPlayback ?
                                            <div className='detailTabCol fullWidth'>
                                                {/*<div className='inpCol playbacCol'>
                                                    <div className='inpCol dmrCol'>
                                                        <h4>DRM <span>?</span></h4>
                                                        <ul>
                                                            <li>
                                                                <label> <input checked type='checkbox'/> Require a token
                                                                    for playback</label>
                                                            </li>
                                                            <li>
                                                                <label> <input type='checkbox'/> Require studio approved
                                                                    DRM for playback</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='inpCol dmrColType'>
                                                        <h4>DRM Types</h4>
                                                        <p> This assets has enabled DRM types. These types were enabled
                                                            during slicing and cannot be edited:</p>
                                                        <ul>
                                                            <li>Widevine and PlayReady</li>
                                                            <li>Fair Play</li>
                                                        </ul>
                                                    </div>
                                                </div>*/}
                                                <div className='inpCol playbacCol'>
                                                    <label>HLS URL</label>
                                                    <input
                                                        name='hSL_URL'
                                                        value={hSL_URL}
                                                        className='inputCol'
                                                        onChange={(e) => setHSL_URL(e.target.value)}
                                                    />
                                                </div>
                                                <div className='inpCol playbacCol'>
                                                    <label>DASH URL</label>
                                                    <input
                                                        name='dash_URL'
                                                        value={dash_URL}
                                                        className='inputCol'
                                                        onChange={(e) => setDash_URL(e.target.value)}
                                                    />
                                                </div>
                                                <div className='inpCol playbacCol'>
                                                    <label>MP4 </label>
                                                    <input
                                                        name='mp4'
                                                        value={mp4}
                                                        className='inputCol'
                                                        onChange={(e) => setMp4(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                :
                null
            }

        </div>
                :
                <div className='userNotLoggedIn'>
                    <div id='login-form-wrap'>User not logged in
                    <div className='fullWidth' id="signUpcol">
                        <p>Click here to <span onClick={goToSignInScreen}>Sign In</span></p>
                        <p>
                        </p>
                </div>
                    </div>
                </div>

        }
        </div>
    );
}

export default Content;

