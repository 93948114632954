import React, {Component} from 'react';
import logo from '../images/logo.png'
import LoaderFullScreen from "./Loading";
import ErrorMessageComponent from "./ErrorMessageComponent";
import {client} from "../config/axiosClient"
const axios = require('axios');

const validateEmail = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    return expression.test(String(email).toLowerCase())
}


class SignIn extends Component {
    constructor(props) {
        super(props)
        /* Initial application state */
        this.state = {
            userEmail: '',
            userEmailForgotPassword: '',
            password: '',
            loading: false,
            disabled: true,
            errorMsg: '',
            open: false,
            textVisible: false,
            passwordType: false,
            error:false,
            errorMessage:false,
        }

    }

    componentDidMount = async () => {

    }


    doSignin = async () => {
        this.setState({loading:true})
        try {
            let res = await client.post("/login", {
                email: this.state.userEmail,
                password: this.state.password
            })
            console.log("res", res)
            this.setState({loading: false})
            if (!res.data.error) {
                localStorage.setItem("UserData", JSON.stringify(res.data));
                localStorage.setItem("client", res.data.client);
                localStorage.setItem("isLoggedIn", "true");
                //this.props.history.push(null, null, window.location.href);
                window.onpopstate = function(event) {
                    window.history.go(1);
                };
                this.props.history.push('/content')
            } else {
                this.setState({error: res.data.error, errorMessage: 'Invalid credentials'})
            }
        }catch (e) {
            console.error("error while login", e)
            this.setState({loading: false})
            this.setState({error: e, errorMessage: 'Invalid credentials'})
        }
    }
    handleOnChangeEmail = (event) => {
        this.setState({
            userEmail: event.target.value.toLowerCase(),
            errorMsg: ''
        })

        const validEmail = validateEmail(event.target.value)

        if (validEmail && this.state.password.length > 4) {
            this.setState({
                disabled: false
            })
        }
    }

    handleOnChangePassword = (event) => {
        this.setState({
            password: event.target.value,
            errorMsg: ''
        })
        if (event.target.value.length > 4) {
            const validEmail = validateEmail(this.state.userEmail)
            if (validEmail) {
                this.setState({
                    disabled: false
                })
            }
        } else {
            this.setState({
                disabled: true
            })
        }
    }

    goToSignUpScreen = () => {
        this.props.history.push('/signup')
    }

    render() {
        return (
            <div className='signInMain'>
                {this.state.loading ?
                    <LoaderFullScreen/>
                    :
                    null
                }
                <div className='header fullWidth'>
                    <div className='logo'>
                        <img src={logo} alt='logo'/>
                        <span className='mediaServicePanel'>Media service panel</span>
                    </div>
                </div>
                <div id="login-form-wrap">
                    <h2>Sign In</h2>
                    {this.state.error ?
					<div className='maxWidth600 marginLeftRightAuto'>
						<ErrorMessageComponent errorMessage={this.state.errorMessage} />
					</div>
                    :
                    null
                    }
                    <div className='fullWidth'>
                        <form id="login-form">
                            <div className='fullWidth'>
                                <input
                                    className='inputCol'
                                    onChange={this.handleOnChangeEmail}
                                    placeholder="Enter your email"
                                    type="email"
                                    name="email"
                                    value={this.state.userEmail}
                                    required=""/>
                                <i className="validation"><span></span><span></span></i>
                            </div>
                            <div className='fullWidth'>
                                <input
                                    className='inputCol'
                                    onChange={this.handleOnChangePassword}
                                    placeholder="Enter your password"
                                    name="password"
                                    type={this.state.passwordType ? "text" : "password"}
                                    required=""/>
                                <i className="validation"><span></span><span></span></i>
                            </div>
                            <div className='fullWidth'>
                                <input type="button" id="login" className='buttonSubmit' onClick={this.doSignin}
                                       value="Sign In"/>
                            </div>
                        </form>
                    </div>
                    {/*<div className='fullWidth' id="signUpcol">
                        <p>Not a member? <span onClick={this.goToSignUpScreen}>Sign Up</span></p>
                        <p>
                        </p>
                    </div>*/}
                </div>
            </div>
        )
    }
}

export default SignIn;

